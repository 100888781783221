import { ComponentType } from "react";
import * as moment from "moment";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import OpenInBrowserOutlinedIcon from "@mui/icons-material/OpenInBrowserOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SignalWifi4BarOutlinedIcon from "@mui/icons-material/SignalWifi4BarOutlined";
import LabelOutlinedIcon from "@mui/icons-material/LabelOutlined";
import CompareArrowsOutlinedIcon from "@mui/icons-material/CompareArrowsOutlined";
import HomeMaxOutlinedIcon from "@mui/icons-material/HomeMaxOutlined";
import TonalityOutlinedIcon from "@mui/icons-material/TonalityOutlined";

import { IFiltersData } from "./Interfaces";
import { FilterKeys } from "./types";

export const emptyCityPlaceholder = "unknown";

export const defaultQualityData = {
  bitrateRecv_max: 2500,
  bitrateRecv_min: 0,
  bitrateSend_max: 2500,
  bitrateSend_min: 0,

  packetLossRecv_min: 0,
  packetLossRecv_max: 100,
  packetLossSend_min: 0,
  packetLossSend_max: 100,

  rttRecv_min: 0,
  rttRecv_max: 500,
  rttSend_min: 0,
  rttSend_max: 500,

  mos_min: 0,
  mos_max: 5,

  rank_min: 0,
  rank_max: 10,
};

export const defaultFiltersData: IFiltersData = {
  time: {
    from: moment().startOf("day").toDate().getTime(),
    to: moment().endOf("day").toDate().getTime(),
  },
  keys: [],
  os: [],
  browsers: [],
  localLocation: [],
  remoteLocation: [],

  networkTypes: [],
  protocols: [],
  ipVersions: [],
  candidateTypes: [],

  ...defaultQualityData,
};

export const filterKeys = {
  os: "os",
  browsers: "browsers",
  localLocation: "localLocation",
  remoteLocation: "remoteLocation",
  networkTypes: "networkTypes",
  ipVersions: "ipVersions",
  protocols: "protocols",
  candidateTypes: "candidateTypes",
  quality: "quality",
} as const;

export const filterMap: Record<FilterKeys, string> = {
  [filterKeys.os]: "Operating System",
  [filterKeys.browsers]: "Browsers",
  [filterKeys.localLocation]: "Device Location",
  [filterKeys.remoteLocation]: "Server Location",
  [filterKeys.networkTypes]: "Connection",
  [filterKeys.ipVersions]: "IP Type",
  [filterKeys.protocols]: "Transport",
  [filterKeys.candidateTypes]: "Session",
  [filterKeys.quality]: "Quality",
} as const;

export const FilterIcons: Record<FilterKeys, ComponentType> = {
  [filterKeys.os]: DesktopWindowsOutlinedIcon,
  [filterKeys.browsers]: OpenInBrowserOutlinedIcon,
  [filterKeys.localLocation]: LocationOnOutlinedIcon,
  [filterKeys.remoteLocation]: LocationOnOutlinedIcon,
  [filterKeys.networkTypes]: SignalWifi4BarOutlinedIcon,
  [filterKeys.ipVersions]: LabelOutlinedIcon,
  [filterKeys.protocols]: CompareArrowsOutlinedIcon,
  [filterKeys.candidateTypes]: HomeMaxOutlinedIcon,
  [filterKeys.quality]: TonalityOutlinedIcon,
} as const;

export const links = [
  { code: "10003", link: "" },
  {
    code: "30100",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/_layouts/15/Doc.aspx?sourcedoc=%7B54A6707B-E1B1-4836-A11F-1E33FA5C7ED6%7D&file=Status%2030100%20User%20changed%20location%20or%20ISP%20since%20last%20run.docx&action=default&mobileredirect=true",
  },
  {
    code: "60015",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%2060015%20Microphone%20camera%20not%20supported.docx?d=w5e7d4490db80440b94160b3e2cf6b205&csf=1&web=1&e=sYwtub",
  },
  {
    code: "60100",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%2060100%20User%20switched%20microphone%20since%20last%20run.docx?d=wf1e296b68cd54455baa21f19a18c15cf&csf=1&web=1&e=xRUZp5",
  },
  {
    code: "60101",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%2060101%20User%20switched%20camera%20since%20last%20run.docx?d=w838bf2ffa5d7419cb2714478d415f8a1&csf=1&web=1&e=DAlp24",
  },
  { code: "2001", link: "" },
  {
    code: "2030",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%202030%20Microphone%20camera%20not%20supported.docx?d=wd4303587a45e4459aee9fef8068ea318&csf=1&web=1&e=HArqYL",
  },
  {
    code: "3500",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%203500%20Call%20failed%20to%20connect.docx?d=wc130cf94199b464eb53be5785eb130a1&csf=1&web=1&e=BvHVkS",
  },
  {
    code: "3600",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%203600%20Call%20closed%20abnormally.docx?d=w577d18429fd84c8497fd458d5b995a41&csf=1&web=1&e=sxPKnn",
  },
  {
    code: "4003",
    link:
      "https://cyara365.sharepoint.com/:w:/r/sites/TestRTC/Test%20RTC/Roadmap/Features%20and%20requirements/Status%20codes%20KB/Status%204003%20No%20media%20for%20an%20hour%20or%20more.docx?d=w71d3862d8876438882fd0e2fdf84aeb7&csf=1&web=1&e=lI9Xb3",
  },
  { code: "4100", link: "" },
  { code: "4220", link: "" },
  { code: "4300", link: "" },
  { code: "6120", link: "" },

  // Mock values
  { code: "2130", link: "https://cyara.com/" },
  { code: "4211", link: "https://cyara.com/" },
  { code: "6110", link: "https://cyara.com/" },
];
