import * as React from "react";
import { AxiosError, AxiosResponse } from "axios";
import { connect } from "react-redux";
import { sortBy } from "lodash";
import * as moment from "moment";
import { saveAs } from "file-saver";

import View from "./View";
import { axiosInstance } from "../../../services/AxiosService";
import ApiPath from "../../../constants/ApiPath";
import { RouteComponentProps } from "react-router";
import { SetNotification } from "../../../actions/notificationAction";
import withBreadcrumb, { WithBreadcrumb } from "../../../components/withBreadcrumb";
import DbUtilsService from "../../../services/DbUtilsService";
import { AppRoutes } from "../../../constants/routes/app-routes";
import { reportError } from "src/services/ErrorService";
import { beautifier } from "src/helpers/jsonBeautifyHelper";

type RouteParams = {
  objectId: string;
};

export interface ProjectPropertiesProps {
  setNotification(message: string): void;
}

export type ProjectPropertiesState = {
  project: any;
  initialValues: any;
  users: Array<any>;
  selectedUsers: Array<any>;
  autocompleteUsers: Array<any>;

  deleteHistoryDialogOpened: boolean;
  deleteProjectDialogOpened: boolean;
  isProjectDataValid: boolean;
  isDeletingProject: boolean;

  config?: string;

  isWatchRtcDataStreamActive: boolean;
  isProbeRtcDataStreamActive: boolean;
  isQualityRtcDataStreamActive: boolean;
};

export const AccountingTypes = {
  evaluation: "Evaluation",
  customer: "Customer",
  suspended: "Suspended",
};

class ProjectProperties extends React.Component<
  ProjectPropertiesProps & RouteComponentProps<RouteParams> & WithBreadcrumb,
  ProjectPropertiesState
> {
  constructor(props: ProjectPropertiesProps & RouteComponentProps<RouteParams> & WithBreadcrumb) {
    super(props);

    this.state = {
      project: null,
      isProjectDataValid: true,
      isDeletingProject: false,
      initialValues: {
        opp_maxActiveProbes: 0,
        opp_normalRun: [],
        opp_thoroughtRun: [],
        domain: "",
        sfdcId: "",
        isSSOAllowed: false,
        editGridPresets: [],
        qualityrtc_maxNetworkTests: 0,
        qualityrtc_retentionPeriod: 30,
        agentAssure_detailedView: false,

        audioRecordAnalysis: {
          overall: false,
          segmented: false,
        },

        maxUsers: 100,
        watchrtc_maxMinutes: 0,
        watchrtc_interval: 1000,
        watchrtc_sendInterval: 1,
        watchrtc_conferenceSizes: "1,2,3,4,5-10,11+",
        watchrtc_conferenceClousureWaitTime: 0,
        watchrtc_ipAddressObfuscation: "none",
        charts_thresholds: JSON.stringify({}), // 'yAxisThresholds' set default values here when no value set by user. Now if no value of 'charts_thresholds' set, project will use default values of configData
        ribbon_thresholds: JSON.stringify({}),
        metric_customisations: JSON.stringify({}),
        _tablesCustomisationSchema: JSON.stringify({}),
        clientStorage: {
          cloudPlatform: "",
          bucketName: "",
          credentials: JSON.stringify({}),
        },
        clientProvider: {
          cloudPlatform: "aws",
        },
      },
      users: [],
      selectedUsers: [],
      autocompleteUsers: [],

      deleteHistoryDialogOpened: false,
      deleteProjectDialogOpened: false,

      isWatchRtcDataStreamActive: false,
      isProbeRtcDataStreamActive: false,
      isQualityRtcDataStreamActive: false,

      config: "",
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.removeUser = this.removeUser.bind(this);
  }

  async componentDidMount() {
    // this.props.pushBreadcrumbNode(this.props.location.pathname, "Project Configuration");
    await this.fetchProject();
    await this.searchUsers();
  }

  async fetchProject() {
    const result: AxiosResponse = await axiosInstance
      .get(`${ApiPath.api.projects.root}/${this.props.match.params.objectId}`)
      .catch((err) => err.response);
    if (!result.data) {
      this.openSnackbar("Project saved.");
    }
    if (result.status !== 200) {
      if (result.status === 404) {
        this.props.history.push(AppRoutes.NotFound);
      } else {
        console.log({ error: result });
        this.props.breadcrumbs.update("#", "");
        this.openSnackbar(result?.data?.message || "Failed to load project data.");
      }
      return;
    }
    result.data.charts_thresholds = result.data.charts_thresholds ? beautifier(result.data.charts_thresholds) : "{}";

    result.data.ribbon_thresholds = result.data.ribbon_thresholds ? beautifier(result.data.ribbon_thresholds) : "{}";
    result.data.metric_customisations = result.data.metric_customisations
      ? beautifier(result.data.metric_customisations)
      : "{}";

    const project = result.data;
    this.props.breadcrumbs.update("#", project.name);
    this.setProject(project);
  }

  setProject(project: any) {
    const maxUsers = project.maxUsers ? project.maxUsers : project.agentAssure_enable ? 2 : 100;
    const selectedUsers = project.usersInfo ? project.usersInfo : [];
    const isWatchRtcDataStreamActive = project.dataStreamUploadConfigs?.watch?.isActiveUploadJob || false;
    const isProbeRtcDataStreamActive = project.dataStreamUploadConfigs?.probe?.isActiveUploadJob || false;
    const isQualityRtcDataStreamActive = project.dataStreamUploadConfigs?.quality?.isActiveUploadJob || false;
    this.setState({
      project,
      initialValues: {
        ...this.state.initialValues,
        ...project,
        maxUsers,
      },
      selectedUsers,
      isWatchRtcDataStreamActive,
      isProbeRtcDataStreamActive,
      isQualityRtcDataStreamActive,
    });
  }

  searchUsers = async (search = "") => {
    const result: AxiosResponse = await axiosInstance.get(`${ApiPath.api.users}/users-search?search=${search}`);
    const users = sortBy(result.data.docs, "email").map((x) => ({
      _id: x._id,
      value: x._id,
      label: x.email,
      email: x.email,
      company: x.company,
      role: x.role,
    }));
    this.setState({
      ...this.state,
      autocompleteUsers: users,
    });
  };

  openSnackbar(message: string) {
    this.props.setNotification(message);
  }

  addUser = (user: any) => {
    const existingUser = this.state.selectedUsers.filter((u: any) => u._id === user._id);
    if (existingUser.length > 0) {
      return;
    }
    this.setState({
      ...this.state,
      selectedUsers: [...this.state.selectedUsers, user],
    });
  };

  removeUser(id: string) {
    const userToRemove = this.state.selectedUsers.filter((u: any) => u._id === id)[0];
    const filteredSelectedUsers = this.state.selectedUsers.filter((u: any) => u._id !== id);
    this.setState({
      ...this.state,
      selectedUsers: filteredSelectedUsers,
      users: sortBy([...this.state.users, userToRemove], "email"),
    });
  }

  generateApiKey = async (values: any) => {
    try {
      await DbUtilsService.rpc("projects", "testingrtc/apikeys", this.state.project._id);

      const result: AxiosResponse = await axiosInstance.get(
        `${ApiPath.api.projects.root}/${this.props.match.params.objectId}`
      );
      this.setState({
        initialValues: {
          ...values,
          apiKey: result.data.apiKey,
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  generateWatchRTCApiKey = async (setWatchRTCApiKey: any) => {
    try {
      const apiKeyResult = await DbUtilsService.rpc("projects", "watchrtc/apikeys", this.state.project._id);
      const apiKey = apiKeyResult?.data;
      setWatchRTCApiKey(apiKey);
    } catch (e) {
      console.log(e.response?.data?.message || e.message);
      this.openSnackbar("Failed to generate WatchRTC API KEY");
    }
  };

  confirmDeleteHistory = async () => {
    try {
      await axiosInstance.get(`${ApiPath.api.projects.root}/delete-history/${this.state.project._id}`);
      this.openSnackbar("History deleted successfully.");
    } catch (err) {
      this.openSnackbar(`Failed to remove the history: ${err.message}`);
    } finally {
      this.toggleDeleteHistoryDialog();
    }
  };

  toggleDeleteHistoryDialog = () => {
    this.setState({
      deleteHistoryDialogOpened: !this.state.deleteHistoryDialogOpened,
    });
  };

  confirmDeleteProject = async () => {
    try {
      this.setState({ isDeletingProject: true });
      const response = await axiosInstance
        .delete(`${ApiPath.api.projects.root}/${this.state.project._id}`)
        .catch((err: AxiosError) => {
          if (err?.response?.data?.message) {
            this.openSnackbar(`Failed to remove the project: ${err?.response?.data?.message}`);
          } else {
            this.openSnackbar(`Failed to remove the project. Unknown error.`);
          }
          return err.response;
        });
      if (response?.status === 200) {
        this.openSnackbar(response.data.message || "Project and related data were removed successfully.");
        this.props.history.push(AppRoutes.Projects);
        return;
      }
    } catch (err) {
      this.openSnackbar(`Failed to remove the project: ${err.message}`);
    } finally {
      this.setState({ isDeletingProject: false });
      this.toggleDeleteProjectDialog();
    }
  };

  toggleDeleteProjectDialog = () => {
    this.setState({
      deleteProjectDialogOpened: !this.state.deleteProjectDialogOpened,
    });
  };

  exportCsv = async (from: any, to: any, omitMonitor: boolean) => {
    try {
      const data = {
        start: from,
        end: to,
        omitMonitor,
        project: this.state.project._id,
        name: this.state.project.name,
      };

      const res = (await axiosInstance.post(`${ApiPath.api.projects.root}/${this.state.project._id}/csv`, data)) as any;
      const blob = new Blob([res.data]);
      saveAs(blob, this.state.project.name + ".csv");
    } catch (err) {
      this.openSnackbar(`Failed to export: ${err.message}`);
    }
  };

  getConfig = async () => {
    if (this.state.config) {
      this.setState({
        ...this.state,
        config: "",
      });
      return;
    }
    try {
      const result: AxiosResponse = await axiosInstance.get(
        `${ApiPath.api.projects.root}/projectSetup/${this.props.match.params.objectId}`
      );
      this.setState({
        ...this.state,
        config: JSON.stringify(result.data, null, 2),
      });
    } catch (err) {
      this.openSnackbar("Could not get configuration");
    }
  };

  testUpRtcEmails = async (alertEmails: string) => {
    if (!alertEmails) {
      return;
    }
    try {
      await axiosInstance.post(`${ApiPath.api.adminUtils.testUpRtcEmails}/${this.props.match.params.objectId}`, {
        alertEmails,
      });
      this.openSnackbar("A test email was sent to the configured email addresses. Please check your inbox");
    } catch (err) {
      console.log(err.message);
      this.openSnackbar("Could not sent test emails.");
    }
  };

  setProjectDataValid = (value: boolean) => {
    this.setState({ isProjectDataValid: value });
  };

  render() {
    return (
      <View
        onSubmit={this.onSubmit}
        {...this.state}
        setProjectDataValid={this.setProjectDataValid}
        addUser={this.addUser}
        removeUser={this.removeUser}
        generateApiKey={this.generateApiKey}
        toggleDeleteHistoryDialog={this.toggleDeleteHistoryDialog}
        confirmDeleteHistory={this.confirmDeleteHistory}
        toggleDeleteProjectDialog={this.toggleDeleteProjectDialog}
        confirmDeleteProject={this.confirmDeleteProject}
        exportCsv={this.exportCsv}
        searchUsers={this.searchUsers}
        getConfig={this.getConfig}
        testUpRtcEmails={this.testUpRtcEmails}
        generateWatchRTCApiKey={this.generateWatchRTCApiKey}
      />
    );
  }

  private async onSubmit(_values: any) {
    try {
      _values.serviceStartDate = moment(_values.serviceStartDate).toISOString();
      _values.paymentExpiry = moment(_values.paymentExpiry).toISOString();
      _values.endTerm = moment(_values.endTerm).toISOString();
      _values.stat_lastTestRun = moment(_values.stat_lastTestRun).toISOString();

      if (!_values.serviceStartDate) {
        _values.serviceStartDate = "";
      }
      if (!_values.domain) {
        _values.domain = "";
      }
      if (!_values.sfdcId) {
        _values.sfdcId = "";
      }
      if (!_values.paymentExpiry) {
        _values.paymentExpiry = "";
      }
      if (!_values.endTerm) {
        _values.endTerm = "";
      }
      if (!_values.stat_lastTestRun) {
        _values.stat_lastTestRun = "";
      }

      if (_values.userConfig) {
        try {
          _values.userConfig = JSON.stringify(JSON.parse(_values.userConfig), null, 2);
        } catch {
          this.openSnackbar("User Configuration (JSON) is invalid JSON");
          return;
        }
      } else {
        _values.userConfig = "";
      }

      if (_values.qualityrtc_webhook) {
        try {
          _values.qualityrtc_webhook = JSON.stringify(JSON.parse(_values.qualityrtc_webhook), null, 2);
        } catch {
          //
        }
      } else {
        _values.qualityrtc_webhook = "";
      }

      if (!_values.qualityrtc_toEmailAddress) {
        _values.qualityrtc_toEmailAddress = "";
      }

      if (!_values.qualityrtc_extraFields) {
        _values.qualityrtc_extraFields = "";
      }

      if (!_values.qualityrtc_showHistoryColumns) {
        _values.qualityrtc_showHistoryColumns = "";
      }

      if (_values.qualityrtc_options) {
        try {
          _values.qualityrtc_options = JSON.stringify(JSON.parse(_values.qualityrtc_options), null, 2);
        } catch {
          this.openSnackbar("QualityRTC Options (JSON) is invalid JSON");
          return { qualityrtc_options: "Invalid JSON" };
        }
      } else {
        _values.qualityrtc_options = "";
      }

      if (_values.qualityrtc_inviteEnable && !_values.qualityrtc_baseURL) {
        this.openSnackbar("QualityRTC Base URL is required when using invites");
        return { qualityrtc_baseURL: "Required when using invites" };
      }

      if (!_values.qualityrtc_baseURL) {
        _values.qualityrtc_baseURL = "";
      }

      if (!_values.watchrtc_conferenceSizes) {
        _values.watchrtc_conferenceSizes = "";
      }
      if (!_values.watchrtc_domains) {
        _values.watchrtc_domains = "";
      }
      if (!_values.watchrtc_embedded_domains) {
        _values.watchrtc_embedded_domains = "";
      }
      if (_values.watchrtc_keys) {
        try {
          const parsedKeys = JSON.parse(_values.watchrtc_keys);

          if (!Array.isArray(parsedKeys)) {
            this.openSnackbar("watchRTC Keys (JSON) should be an array");
            return { watchrtc_keys: "watchRTC key should be an array" };
          }

          _values.watchrtc_keys = JSON.stringify(parsedKeys, null, 2);
        } catch {
          this.openSnackbar("watchRTC Keys (JSON) is invalid JSON");
          return { watchrtc_keys: "Invalid JSON" };
        }
      } else {
        _values.watchrtc_keys = "";
      }

      if (_values._tablesCustomisationSchema) {
        try {
          _values._tablesCustomisationSchema = beautifier(_values._tablesCustomisationSchema);
        } catch {
          this.openSnackbar("Tables Schema (JSON) is invalid JSON");
          return { _tablesCustomisationSchema: "Invalid JSON" };
        }
      } else {
        _values._tablesCustomisationSchema = "{}";
      }

      if (_values.charts_thresholds) {
        try {
          _values.charts_thresholds = beautifier(_values.charts_thresholds);
        } catch {
          this.openSnackbar("Charts Thresholds (JSON) is invalid JSON");
          return { charts_thresholds: "Invalid JSON" };
        }
      } else {
        _values.charts_thresholds = "";
      }

      if (_values.ribbon_thresholds) {
        try {
          _values.ribbon_thresholds = beautifier(_values.ribbon_thresholds);
        } catch {
          this.openSnackbar("Ribbon Thresholds (JSON) is invalid JSON");
          return { ribbon_thresholds: "Invalid JSON" };
        }
      } else {
        _values.ribbon_thresholds = "";
      }

      if (_values.metric_customisations) {
        try {
          _values.metric_customisations = beautifier(_values.metric_customisations);
        } catch {
          this.openSnackbar("Metric Customisation (JSON) is invalid JSON");
          return { metric_customisations: "Invalid JSON" };
        }
      } else {
        _values.metric_customisations = "";
      }

      if (!_values.watchrtc_roomIdURLTemplate) {
        _values.watchrtc_roomIdURLTemplate = "";
      }
      if (!_values.watchrtc_peerIdURLTemplate) {
        _values.watchrtc_peerIdURLTemplate = "";
      }

      _values.users_roles = this.state.selectedUsers.map((x) => {
        const user: any = {
          userId: x._id.toString(),
        };
        if (x.role !== "admin") {
          user["roleId"] = x.roleId;
        }
        return user;
      });

      if (_values.clientStorage.credentials) {
        try {
          _values.clientStorage.credentials = beautifier(_values.clientStorage.credentials || {});
        } catch {
          this.openSnackbar("Credentials in clientStorage (JSON) is invalid JSON");
          return { credentials: "Invalid JSON" };
        }
      }

      const pathToFiles = _values.dataStreamUploadConfigs?.watch?.pathToFiles;

      if (pathToFiles) {
        if (pathToFiles[0] === "/") {
          _values.dataStreamUploadConfigs.watch.pathToFiles = pathToFiles.substring(1);
        }

        if (pathToFiles[pathToFiles.length - 1] !== "/") {
          _values.dataStreamUploadConfigs.watch.pathToFiles += "/";
        }
      }

      if (_values.clientStorage.cloudPlatform) {
        _values.clientStorage.cloudPlatform = _values.clientStorage.cloudPlatform.trim();
      }

      if (_values.clientStorage.cloudPlatform === "") {
        _values.clientStorage.cloudPlatform = undefined;
      }

      if (_values.clientStorage.bucketRegion) {
        _values.clientStorage.bucketRegion = _values.clientStorage.bucketRegion.trim();
      }
      if (_values.clientStorage.bucketRegion === "") {
        _values.clientStorage.bucketRegion = undefined;
      }

      if (_values.clientProvider.cloudPlatform) {
        _values.clientProvider.cloudPlatform = _values.clientProvider.cloudPlatform.trim();
      }

      if (_values.clientProvider.cloudPlatform === "") {
        _values.clientProvider.cloudPlatform = "aws";
      }

      const response = await axiosInstance({
        url: `${ApiPath.api.projects.root}/${this.state.project._id}`,
        method: "PUT",
        data: _values,
      });
      if (!response.data) {
        this.openSnackbar("Could not update the project.");
      } else {
        response.data.charts_thresholds = beautifier(response.data.charts_thresholds);
        response.data.ribbon_thresholds = beautifier(response.data.ribbon_thresholds);
        response.data.metric_customisations = beautifier(response.data.metric_customisations);
        response.data.clientStorage.credentials = beautifier(response.data.clientStorage.credentials);
        response.data._tablesCustomisationSchema = beautifier(JSON.parse(response.data._tablesCustomisationSchema));

        this.postSubmit(response.data);
        this.setProject(response.data);
        this.openSnackbar("Project saved.");
      }
      return;
    } catch (err) {
      if (err.response && err.response.data?.message) {
        this.openSnackbar(err.response.data.message || "Could not update the project.");
      } else {
        this.openSnackbar("Could not update the project.");
      }
      return;
    }
  }

  private async postSubmit(updatedProject: any) {
    // https://redmine.testrtc.com/issues/5397
    // populate-watchrtc-influxdb script was change
    // need to review with Muly how correctly update data
    // in this case
    return;
    if (
      updatedProject.watchrtc_conferenceSizes &&
      updatedProject.watchrtc_conferenceSizes !== this.state.initialValues.watchrtc_conferenceSizes
    ) {
      try {
        const command = `populate-watchrtc-influxdb ${this.state.project._id} 24 drop`;

        await axiosInstance.get(`${ApiPath.api.adminUtils.adminCmd}/${encodeURIComponent(command)}`);
      } catch (err) {
        reportError("Execute admin command error", err);
      }
    }
  }
}

// tslint:disable-next-line:no-any
const mapDispatchToProps = (dispatch: any) => ({
  setNotification: (message: string) => dispatch(SetNotification(message)),
});

export default withBreadcrumb(connect(null, mapDispatchToProps)(ProjectProperties));
