import * as _ from "lodash";
import { Dataset, LegendSeries } from "src/components/Chart/types";

export default class ChartHelper {
  static flatEvents(events: Array<any>) {
    const flattenArrayOfEvents: Array<any> = [];

    events.forEach((eventsArray) => {
      Array.prototype.push.apply(flattenArrayOfEvents, eventsArray);
    });

    return flattenArrayOfEvents;
  }

  // just sum two arrays
  static sumArray(left: any, right: any, isAvg: boolean) {
    // tslint:disable-next-line:no-shadowed-variable
    let idx;
    let len = 0;
    const slice = Array.prototype.slice;

    // use slice to not store reference to origin arrays
    // because it may change value right there
    const lArr = slice.call(left),
      rArr = slice.call(right);

    // if lArr larger then we sum values there
    // in other case vice versa
    let dst, src;
    if (lArr.length > rArr.length) {
      dst = lArr;
      src = rArr;
    } else {
      src = lArr;
      dst = rArr;
    }

    len = src.length;
    for (idx = 0; idx < len; idx++) {
      if (isAvg) {
        /* undefined will be coerced to false */
        dst[idx] = (+dst[idx] + +src[idx]) / 2;
      } else {
        dst[idx] += +src[idx];
      }
    }

    return dst;
  }

  // we get data like array of objects
  // for chart we need array of numbers
  static prepareFilterOptions(filterObj: any, _sortBy = "probeId", dataFrequency = 1) {
    let outObj = {
      audio: { send: {}, recv: {} },
      video: { send: {}, recv: {} },
      data: { send: {}, recv: {} },
    };

    if (filterObj) {
      const mediaRefs = Object.keys(filterObj);
      const directionRefs =
        _sortBy === "send" ? Object.keys(filterObj[mediaRefs[0]]).reverse() : Object.keys(filterObj[mediaRefs[0]]);

      for (const mRef of mediaRefs) {
        for (const dRef of directionRefs) {
          // tslint:disable-next-line: forin
          for (const type in filterObj[mRef][dRef]) {
            if (type) {
              if (!Array.isArray(outObj[mRef][dRef][type])) {
                outObj[mRef][dRef][type] = [];
              }

              let array = filterObj[mRef][dRef][type];
              if (_sortBy === "probeId") {
                if (!array) {
                  console.log("error", { filterObj, mRef, dRef, type });
                }
                array = array.sort((a: any, b: any) => {
                  return a?.probeId?.localeCompare(b.probeId, undefined, {
                    numeric: true,
                    sensitivity: "base",
                  });
                });
              }

              const tmpTypeArray = array.map((el: any, _idx: any, _arr: any) => {
                if (el !== null) {
                  const val =
                    el.value && el.value.hasOwnProperty("average") && type !== "packets"
                      ? el.value.average
                      : type !== "packets"
                      ? el.value
                      : el.value.total;
                  return _.round(val, 2);
                } else {
                  return 0;
                }
              });

              Array.prototype.push.apply(outObj[mRef][dRef][type], tmpTypeArray);
            }
          }
        }
      }
    }

    outObj = adjustPacketLossPCT(outObj, dataFrequency);
    return outObj;
  }

  // private fns end border

  static prepareGlobalEvents(eventsObj: any) {
    const type = "global";
    // like [ [evt, evt], [...] ]
    const arrayOfEventsArray = eventsObj.map((obj: any) => {
      // let onlyGlobal = obj.events.filter( evt => {
      //   return evt.type === 'global';
      // });

      // INFO: adding runIndex for multi iterations
      // eslint-disable-next-line
      let globalEventsOnly = (obj.events || []).map((evt: any) => {
        if (evt.type === type) {
          evt.runIndex = obj.runIndex;
          return { ...evt, inSessionIdx: obj.inSessionIdx, machine: obj.machine };
        }
      });

      // return onlyGlobal;
      return globalEventsOnly;
    });

    return this.flatEvents(arrayOfEventsArray);
  }

  // TODO: I think better to make it private and use here not in public
  // still thinking about it, will see what's better
  // no return due to sort behavior
  static sortEventsAsc(events: Array<any>) {
    events.sort((a, b) => {
      return (new Date(a.timestamp) as any) - (new Date(b.timestamp) as any);
    });
  }

  // small trick ;)
  // do not chain because sort do its job in place
  static sortEventsDesc(events: Array<any>) {
    this.sortEventsAsc(events);
    events.reverse();
  }

  // merge audio and video info all
  // which will contain all summed data
  static audioVideoMerge(info: any, isAvg: boolean) {
    const dst = _.cloneDeep(info);
    // prepare property which we will put inside global summed object
    const all = { send: {}, recv: {} };

    // travers send and recv fields and sum them | all
    if (dst.audio) {
      ["recv", "send"].forEach((direction: any) => {
        // let us suppose that send and recv has the same properties
        //  which has to be true in all cases
        for (const prop in dst.audio[direction]) {
          if (dst.audio[direction].hasOwnProperty(prop)) {
            // just to be sure
            if (prop === "loss") {
              this.prepareLoss(dst.audio[direction][prop]);
              if (dst.video && dst.video[direction] && dst.video[direction].hasOwnProperty(prop)) {
                this.prepareLoss(dst.video[direction][prop]);
              }
            }

            const audio =
              dst.audio && dst.audio[direction] && dst.audio[direction][prop] ? dst.audio[direction][prop] : [];
            const video =
              dst.video && dst.video[direction] && dst.video[direction][prop] ? dst.video[direction][prop] : [];

            all[direction][prop] = this.sumArray(audio, video, isAvg);
          }
        }
      });
    }

    // modify the source object
    dst.all = all;
    return dst;
  }

  static prepareLoss(loss: Array<any>) {
    let prevValue = 0;
    for (let i = 0; i < loss.length - 1; i++) {
      const temp = loss[i];
      loss[i] = loss[i] - prevValue < 0 ? 0 : loss[i] - prevValue;

      prevValue = temp;
    }

    if (loss[loss.length - 1] < 0) {
      loss[loss.length - 1] = 0;
    }
  }

  // find percents for loss packets chart
  // https://redmine.testrtc.com/issues/4235
  // recalculate % only for recv
  static calculatePercents(fullPackets: any, lostPackets: any, recv = false) {
    // 20 / 200 * 100
    const output = [];

    let idx = 0;
    const len = lostPackets.length;
    let percent = 0;
    for (idx = 0; idx < len; idx++) {
      if (recv) {
        percent = (lostPackets[idx] / (lostPackets[idx] + fullPackets[idx])) * 100;
      } else {
        percent = (lostPackets[idx] / fullPackets[idx]) * 100;
      }
      output.push(percent);
    }

    return output;
  }

  static prepareFilterPerformance(perfData: any) {
    const res = {};
    ["browserCpu", "browserMemory"].forEach((prop) => {
      if (perfData.hasOwnProperty(prop)) {
        res[prop] = perfData[prop];
      } else {
        res[prop] = {
          avg: [],
          min: [],
          max: [],
        };
      }
    });
    return res;
  }

  static prepareFilterProbePerformance(probePerf: any) {
    const res = {};
    ["browserCpu", "browserMemory"].forEach((prop) => {
      res[prop] = {
        avg: [],
        min: [],
        max: [],
      };
      if (probePerf.hasOwnProperty(prop)) {
        const arr = probePerf[prop].sort((a: any, b: any) => {
          return a?.probeId?.localeCompare(b.probeId, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        arr.forEach((probeData: any) => {
          ["avg", "max", "min"].forEach((metric) => {
            res[prop][metric].push(probeData.values[metric]);
          });
        });
      }
    });
    return res;
  }

  static getSeriesType(series: Dataset) {
    if (series.type) {
      return series.type;
    }
    if (series.bars && series.bars.show) {
      return "column";
    }
    return series.data &&
      series.data.length &&
      series.data[series.data.length - 1].length &&
      series.data[series.data.length - 1].length === 3
      ? "arearange"
      : "line";
  }

  static getLegendSeries(
    datasets: (Dataset | Dataset[])[],
    allowDatasetToHaveNoData = false,
    shouldHideSeriesIfHasNoData = false,
    links?: { code: string; link: string }[]
  ) {
    const legendSeries: LegendSeries[] = [];

    const sortedDatasets = datasets.sort(
      (datasetA, datasetB) =>
        (Array.isArray(datasetB) ? datasetB.length : 0) - (Array.isArray(datasetA) ? datasetA.length : 0)
    );
    const chartSeriesMap = {};

    let doSomeSeriesHaveData = false;

    for (const dataset of sortedDatasets) {
      if (Array.isArray(dataset)) {
        for (const chartSeries of dataset) {
          if (chartSeries.data?.length) {
            const doesSeriesHavePointsWithData = chartSeries.data.some((point) => Boolean(point[1]));

            if (doesSeriesHavePointsWithData) {
              doSomeSeriesHaveData = true;
            }

            if (!shouldHideSeriesIfHasNoData || doesSeriesHavePointsWithData) {
              const key = `${chartSeries.label}${chartSeries.color ?? ""}`;
              chartSeriesMap[key] = chartSeries;
            }
          }
        }
      } else {
        const chartSeries = dataset;

        if (chartSeries.data?.length) {
          const doesSeriesHavePointsWithData = chartSeries.data.some((point) => Boolean(point[1]));

          if (doesSeriesHavePointsWithData) {
            doSomeSeriesHaveData = true;
          }

          if (!shouldHideSeriesIfHasNoData || doesSeriesHavePointsWithData) {
            const key = `${chartSeries.label}${chartSeries.color ?? ""}`;
            chartSeriesMap[key] = chartSeries;
          }
        }
      }
    }

    if (allowDatasetToHaveNoData || doSomeSeriesHaveData) {
      for (const chartSeries of Object.values<any>(chartSeriesMap)) {
        const series: LegendSeries = {
          name: chartSeries.label,
          type: ChartHelper.getSeriesType(chartSeries),
        };

        if (chartSeries.color) {
          series.color = chartSeries.color;
        }

        if (chartSeries.options?.color) {
          series.options = { color: chartSeries.options.color };
        }

        // do not set visible as undefined
        // as it resets toggled legend item on switch between sources
        if (chartSeries.visible !== undefined) {
          series.visible = chartSeries.visible;
        }

        legendSeries.push(series);
      }
    }

    if (links && links.length > 0) {
      const codeToLinkMap = links.reduce((map, item) => {
        const trimmedCode = item.code.trim();
        map[trimmedCode] = item.link;
        return map;
      }, {});

      for (const series of legendSeries) {
        const code = series.name.split(/\s*-\s*/)[0].trim();
        if (codeToLinkMap[code]) {
          series.link = codeToLinkMap[code];
        }
      }
    }

    return legendSeries;
  }
}

// https://redmine.testrtc.com/issues/7191
// before this commit we were building packetLossPCT data on UI
// after - we return packetLossPCT object from backend
// so to make old records to work along with new ones
// I moved forced calculation of PL percentage on high level, so now we have generic code
// further and no need to add any if statements more
// this can be removed when we will have only new records, with PL percentage data from backend
// maybe v37-v38
const adjustPacketLossPCT = (data: any, dataFrequency = 1) => {
  ["audio", "video", "data"].forEach((media: string) => {
    ["send", "recv"].forEach((direction: string) => {
      if (!data[media][direction].packetLossPCT) {
        data[media][direction].packetLossPCT = new Array(data[media][direction].packets?.length || 0).fill(0);

        if (direction === "recv") {
          for (let i = 0; i < data[media][direction].packets?.length || 0; i++) {
            if (data[media][direction].packets[i] && data[media][direction].packetLoss[i]) {
              const res =
                (data[media][direction].packetLoss[i] /
                  (data[media][direction].packets[i] * dataFrequency + data[media][direction].packetLoss[i])) *
                100;
              data[media][direction].packetLossPCT[i] = parseFloat(res.toFixed(2));
            }
          }
        } else if (direction === "send") {
          for (let i = 0; i < data[media][direction].packets?.length || 0; i++) {
            if (data[media][direction].packets[i] && data[media][direction].packetLoss[i]) {
              const res =
                (data[media][direction].packetLoss[i] / (data[media][direction].packets[i] * dataFrequency)) * 100;
              data[media][direction].packetLossPCT[i] = parseFloat(res.toFixed(2));
            }
          }
        }
      }
    });
  });

  return data;
};
